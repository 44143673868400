import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicQuestions from 'components/Fragebogen/DynamicQuestions';
import ProgressBar from 'styles/ProgressBar';
import usePatient from 'hooks/usePatient';
import { useOnboardingNavigation } from 'providers/onboarding-navigation';
import useQuestionnaire from 'components/Fragebogen/useQuestionnaire';

const NumberOfSteps = 7;

export default function Fragebogen() {
  const { step, setStep } = useOnboardingNavigation();
  const navigate = useNavigate();
  const { data } = usePatient();
  const questionaire = useQuestionnaire();

  useEffect(() => {
    if (questionaire) {
      navigate('/therapie');
    }
    setStep(0);
  }, [questionaire]);

  if (!data) return <></>;

  return (
    <div className="flex flex-col items-center justify-center py-5 mb-10">
      <div className="w-80 m-auto">
        <ProgressBar max={NumberOfSteps} current={step} />
      </div>
      <DynamicQuestions
        className={step < NumberOfSteps ? '' : 'hidden'}
        currentStep={step}
        isMale={data.gender === 'm'}
        onContinue={() => {
          const nextStep = step + 1;
          window.dataLayer.push({
            event: 'patienten-preassessment',
            eventStatus: `step-${nextStep}`,
          });
          setStep(nextStep);
        }}
        onSubmit={() => {
          window.dataLayer.push({
            event: 'patienten-preassessment-submit',
          });
          navigate('/therapie');
        }}
      />
    </div>
  );
}
