import { PatientName } from "hooks/usePatient";
import { CountryCode } from "localizations/types";

export const InfoBox = () => {
  const LOCALIZATION = process.env.REACT_APP_COUNTRY_CODE as CountryCode;
  const price = LOCALIZATION === 'DE' ? '39€' : '99 CHF';
  return (
    <div className="relative overflow-hidden bg-swopa-grey-2 rounded">
      <div className="text-swopa-accent-green relative z-10">
        <div className="px-8 py-4 sm:px-16 sm:py-8 flex flex-col">
          <h3 className="text-swopa-primary-dark-blue text-xl">Willkommen, <PatientName />!</h3><br />
          <p className="mb-4 text-swopa-primary-dark-blue">
            Schön, Sie zu sehen! Wenn Sie einen Folgetermin benötigen, beträgt der Preis nur <b>{price}</b>.<br />
            Buchen Sie Ihren nächsten Termin jetzt online.
          </p>
        </div>
      </div>
    </div>
  );
};
