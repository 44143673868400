import Button from '../styles/Button';
import usePrescriptions from './Therapy/usePrescriptions';
import { useNavigate } from 'react-router-dom';
import usePatient from 'hooks/usePatient';
import { useMemo } from 'react';
import { useBookingUrl } from '../providers/BookingUrl';

const StickyBookingButton = () => {
  const { data: prescriptions } = usePrescriptions();
  const navigate = useNavigate();
  const { setBookingUrl } = useBookingUrl();
  const { data: patient } = usePatient();

  const displayBookingButton = useMemo(
    () => patient && !!prescriptions?.length,
    [patient, prescriptions],
  );

  if (!displayBookingButton) {
    return null;
  }

  return (
    <div className="xl:hidden fixed bottom-0 w-full p-2 flex items-center justify-center bg-white border-t border-swopa-border">
      <Button
        className="max-w-52 !text-sm"
        size="small"
        onClick={() => {
          patient && setBookingUrl(patient.following_appointment_url);
          navigate('/termin');
        }}
      >
        Online Termin Buchen
      </Button>
    </div>
  );
};
export default StickyBookingButton;
