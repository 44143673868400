import * as Yup from 'yup';
import { readFileAsync } from 'components/util/files';
import http from 'components/util/http';
import { useMutation } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import Button from 'styles/Button';
import React, { useEffect, useState } from 'react';
import FormikTextarea from 'styles/FormikTextarea';
import FileInput from 'styles/FileInput';
import FormikMultiSelect from 'styles/FormikMultiSelect';
import FormikRangeSelect from 'styles/FormikRangeSelect';
import FormikBinary from '../../styles/FormikBinary';
import FormikCheckbox from './FormikCheckbox';

const SYMPTOM_OPTIONS = [
  {
    label: 'Chronische Schmerzen',
    value: 'Chronische Schmerzen',
  },
  {
    label: 'ADHS',
    value: 'ADHS',
  },
  {
    label: 'Migräne',
    value: 'Migräne',
  },
  {
    value: 'Depression',
    label: 'Depression',
  },
  {
    label: 'Schlafstörung',
    value: 'Schlafstörung',
  },
  {
    label: 'Krebserkrankung',
    value: 'Krebserkrankung',
  },
  {
    label: 'Angststörung',
    value: 'Angststörung',
  },
  {
    label: 'Andere Nervensystem-Erkrankung',
    value: 'Andere Nervensystem-Erkrankung',
  },
];

const DISEASE_OPTIONS = [
  {
    label: 'Psychose (Schizophrenie, Wahnvorstellungen, Halluzinationen)',
    value: 'Psychose (Schizophrenie, Wahnvorstellungen, Halluzinationen) ',
  },
  {
    label: 'Persönlichkeitsstörung (z. B. Borderline)',
    value: 'Persönlichkeitsstörung (z. B. Borderline)',
  },
  {
    label: 'Bipolare Störung',
    value: 'Bipolare Störung',
  },
  {
    label: 'Suchterkrankung',
    value: 'Suchterkrankung',
  },
  {
    label: 'Koronare Herzkrankheit, Herzinsuffizienz, Herzrhythmusstörung',
    value: 'Koronare Herzkrankheit, Herzinsuffizienz, Herzrhythmusstörung',
  },
  {
    label: 'Herzinfarkt, Schlaganfall, Thrombose/Embolie',
    value: 'Herzinfarkt, Schlaganfall, Thrombose/Embolie',
  },
  {
    label: 'Schwere Leber- oder Nierenerkrankung',
    value: 'Schwere Leber- oder Nierenerkrankung',
  },
  {
    label: 'Allergie gegen THC/CBD-haltige Produkte',
    value: 'Allergie gegen THC/CBD-haltige Produkte',
  },
];

const symptomsSchema = Yup.object().shape({
  symptoms: Yup.array()
    .of(Yup.string())
    .min(1)
    .required('Bitte wählen Sie mindestens eine Option aus'),
  symptoms_description: Yup.string(),
  symptoms_diagnosis_document: Yup.mixed(),
});

const symptomSeveritySchema = Yup.object().shape({
  symptom_severity: Yup.number().required('Bitte wählen Sie eine Option aus'),
});

const previousTreatmentSchema = Yup.object().shape({
  previous_treatment: Yup.boolean().required('Bitte wählen Sie eine Option aus'),
  previous_treatment_details: Yup.string(),
  previous_treatment_document: Yup.mixed(),
});

const cannabisTreatmentSchema = Yup.object().shape({
  cannabis_treatment: Yup.boolean().required('Bitte wählen Sie eine Option aus'),
  cannabis_treatment_details: Yup.string(),
  cannabis_treatment_document: Yup.mixed(),
});

const existingConditionsSchema = Yup.object().shape({
  existing_conditions: Yup.array()
    .of(Yup.string())
    .required('Bitte wählen Sie mindestens eine Option aus'),
});

const pregnantOrBreastfeedingSchema = Yup.object().shape({
  pregnant_or_breastfeeding: Yup.boolean().required('Bitte wählen Sie eine Option aus'),
});

const schemas = [
  symptomsSchema,
  symptomSeveritySchema,
  previousTreatmentSchema,
  cannabisTreatmentSchema,
  existingConditionsSchema,
  pregnantOrBreastfeedingSchema,
];

const questionSchema = Yup.object().shape({
  ...symptomsSchema.fields,
  ...symptomSeveritySchema.fields,
  ...previousTreatmentSchema.fields,
  ...cannabisTreatmentSchema.fields,
  ...existingConditionsSchema.fields,
  ...pregnantOrBreastfeedingSchema.fields,
});

const sectionHeadingStyle =
  'md:text-2xl text-swopa-primary-dark-blue font-bold mb-2 text-center mx-auto';

const labelStyle = 'md:text-lg text-swopa-primary-dark-blue mb-2';

type DynamicQuestionsProps = {
  currentStep: number;
  onContinue: () => void;
  onSubmit: () => void;
  className: string;
  isMale: boolean;
};

type DynamicQuestionsAnswers = {
  symptoms: Array<string>;
  symptoms_description: string;
  symptom_severity: number;
  previous_treatment: boolean | undefined;
  previous_treatment_details: string;
  cannabis_treatment: boolean | undefined;
  cannabis_treatment_details: string;
  existing_conditions: Array<string>;
  additional_notes: string;
  pregnant_or_breastfeeding: boolean | undefined;
  lack_of_existing_conditions_statement: boolean;
};

interface ForceExistingConditionsSwitchProps {
  initialValues?: DynamicQuestionsAnswers;
}

export default function DynamicQuestions({
  onContinue,
  currentStep,
  onSubmit,
  className,
  isMale,
}: DynamicQuestionsProps) {
  const initialValues: DynamicQuestionsAnswers = {
    symptoms: [],
    symptoms_description: '',
    symptom_severity: 5,
    previous_treatment: undefined,
    previous_treatment_details: '',
    cannabis_treatment: undefined,
    cannabis_treatment_details: '',
    existing_conditions: [],
    additional_notes: '',
    pregnant_or_breastfeeding: isMale ? false : undefined,
    lack_of_existing_conditions_statement: false,
  };

  const [files, setFiles] = useState<Record<number, File>>();

  const submitSurvey = useMutation({
    mutationFn: async (val: any) => {
      type Response = {
        id: number;
        asset: string;
        name: string;
      };
      await Promise.all(
        Object.keys(files || {}).map(async (key: any) => {
          if (!files) return;
          const file = files[key];
          if (!file) return;
          const blob = (await readFileAsync(file)) as string;
          // if the file is empty don't upload it
          if (!blob || blob.endsWith('base64,')) return;
          const res: Response = await http.post('/medical/documents/', {
            asset: blob,
            name: file.name,
          });
          val[key] = {
            id: res.id,
            name: res.name,
          };
        }),
      );
      return http.post('/medical/patient-survey/', {
        ...val,
      });
    },
    onSuccess: () => onSubmit(),
    onError: (e) => console.error(e),
  });

  const handleContinue = (val: any) => {
    const schema = schemas[currentStep];
    if (!schema) return true;
    try {
      schema.validateSync(val);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const ForceExistingConditionsSwitch = () => {
    const [previousValues, setPreviousValues] = useState(structuredClone(initialValues));

    const { values, setFieldValue } = useFormikContext<DynamicQuestionsAnswers>();

    useEffect(() => {
      if (
        values.lack_of_existing_conditions_statement &&
        previousValues.existing_conditions.length
      ) {
        setFieldValue('existing_conditions', []);
      }

      if (
        previousValues.lack_of_existing_conditions_statement &&
        values.existing_conditions.length
      ) {
        setFieldValue('lack_of_existing_conditions_statement', false);
      }

      setPreviousValues(values);
    }, [values.lack_of_existing_conditions_statement, values.existing_conditions]);

    return null;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validationSchema={questionSchema}
      validateOnBlur={false}
      validateOnMount={false}
      validateOnChange={false}
    >
      {({
        errors,
        handleSubmit,
        values,
        setErrors,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit} className={className}>
          <div className="px-4 space-y-5 max-w-md">
            {currentStep === 0 && (
              <>
                <h2 className={sectionHeadingStyle}>
                  Wegen welchen Beschwerden oder Symptome möchten Sie einen Termin buchen?
                </h2>
                <FormikMultiSelect name="symptoms" options={SYMPTOM_OPTIONS} />
                <h3 className={labelStyle}>
                  Andere, hier nicht, aufgeführte Symptome/Erkrankungen:
                </h3>
                <FormikTextarea name="symptoms_description" placeholder="Beschreibung" max={200} />
                <h3 className={labelStyle}>
                  Wurde bereits eine Diagnose aufgrund ihrer Beschwerden oder Symptome gestellt,
                  dann laden Sie bitte nach Möglichkeit eine Dokumentation hoch:
                </h3>
                <FileInput
                  id="symptoms_diagnosis_document"
                  onChange={(file) =>
                    setFiles((prev) => ({ ...prev, ['symptoms_diagnosis_document']: file }))
                  }
                />
              </>
            )}
            {currentStep === 1 && (
              <>
                <h2 className={sectionHeadingStyle}>
                  Wie stark sind Ihre Beschwerden (innerhalb der letzten 3 Monate)?
                </h2>
                <FormikRangeSelect
                  name="symptom_severity"
                  min={1}
                  max={10}
                  minLabel="Keine/kaum Beschwerden"
                  maxLabel="Stärkste Beschwerden"
                />
              </>
            )}
            {currentStep === 2 && (
              <>
                <h2 className={sectionHeadingStyle}>
                  Ist bereits eine Therapie aufgrund dieser Beschwerden erfolgt?:
                </h2>
                <FormikBinary name="previous_treatment" />
                <h3 className={labelStyle}>Falls ja, welche:</h3>
                <FormikTextarea name="previous_treatment_details" placeholder="Beschreibung" />
                <h3 className={labelStyle}>
                  Wenn möglich, laden Sie bitte die Dokumentation hoch:
                </h3>
                <FileInput
                  id="previous_treatment_document"
                  onChange={(file) =>
                    setFiles((prev) => ({ ...prev, ['previous_treatment_document']: file }))
                  }
                />
              </>
            )}
            {currentStep === 3 && (
              <>
                <h2 className={sectionHeadingStyle}>
                  Wurden Sie bereits mit medizinischem Cannabis therapiert?
                </h2>
                <FormikBinary name="cannabis_treatment" />
                <h3 className={labelStyle}>Falls ja, welche:</h3>
                <FormikTextarea name="cannabis_treatment_details" placeholder="Beschreibung" />
                <h3 className={labelStyle}>
                  Wenn möglich, laden Sie bitte die Dokumentation hoch:
                </h3>
                <FileInput
                  id="cannabis_treatment_document"
                  onChange={(file) =>
                    setFiles((prev) => ({ ...prev, ['cannabis_treatment_document']: file }))
                  }
                />
              </>
            )}
            {currentStep === 4 && (
              <>
                <h2 className={sectionHeadingStyle}>Andere Krankheiten</h2>
                <h3 className={labelStyle}>
                  Wähle die Erkrankung aus, sofern sie auf dich zutrifft ODER bestätige an keiner
                  der folgenden Erkrankungen zu leiden
                </h3>
                <FormikMultiSelect name="existing_conditions" options={DISEASE_OPTIONS} />
                <FormikCheckbox
                  name="lack_of_existing_conditions_statement"
                  id="lack_of_existing_conditions_statement"
                  className="mt-5"
                  label="Hiermit bestätige ich an keiner der genannten Erkrankungen zu leiden"
                />
                <ForceExistingConditionsSwitch />
              </>
            )}
            {currentStep === 5 && (
              <>
                <h2 className={sectionHeadingStyle}>Sind Sie schwanger oder stillen Sie?</h2>
                <FormikBinary name="pregnant_or_breastfeeding" />
                <h3 className={labelStyle}>
                  Eine Medizinalcannabistherapie ist kontraindiziert bei Schwangerschaft und
                  Stillzeit.
                </h3>
              </>
            )}
            {currentStep === 6 && (
              <>
                <h2 className={sectionHeadingStyle}>Möchten Sie uns noch etwas mitteilen?</h2>
                <FormikTextarea name="additional_notes" placeholder="Anmerkungen" />
              </>
            )}
            {currentStep < 6 ? (
              <Button
                type="button"
                onClick={() => {
                  const isValid = handleContinue(values);
                  if (isValid) {
                    setErrors({});
                    onContinue();
                  } else {
                    handleSubmit();
                  }
                }}
                loading={submitSurvey.isPending}
                className="!mt-20"
              >
                Weiter
              </Button>
            ) : (
              <Button
                type="submit"
                loading={submitSurvey.isPending}
                className="!mt-20"
                onClick={() => submitSurvey.mutate(values)}
              >
                Absenden
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
