import Button from '../styles/Button';
import { useNavigate } from 'react-router-dom';



export default function AppointmentBookingConfirmFooter() {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="appointmentBookingConfirmFooter bottom-0 h-16 max-w-[100%] bg-[#ffffff] flex flex-row
                   m-auto lg:z-0 sm:z-100 items-center relative top-0 justify-center grid"
        id="eterminFooter">
        <Button
          className="flex flex-col w-[300px] lg:w-[220px] sm:w-[320px] relative"
          onClick={() => navigate('/therapie/verschreibungen')}>
          ZURÜCK ZUR ÜBERSICHTSSEITE
        </Button>
      </div>
    </>
  );
}
