import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from 'react';

interface DatepickerProps {
  id: string;
  type: string;
  name: string;
  error?: boolean;
  errorMessage?: any;
  className?: string;
  label?: string;
  value: Date,
  onChange: (newValue: Date) => void;
}

export default function FormikDatepicker(props: DatepickerProps) {
  const initialDate = props.value ? new Date(props.value) : null;
  const [date, setDate] = useState<Date | null>(initialDate);

  useEffect(() => {
    if(date && !isNaN(date.getTime())){
      props.onChange(date);
    }
  }, [date])

  const datePickerStyles = {
    "& .MuiInputBase-root": {
      border: props.error ? "red" : "none",
      backgroundColor: "#F8F8F8",
      color: props.error ? '#E9082A' : '#2D4370',
      fontSize: "0.9rem",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: props.error ? "1px solid #E9082A" : "none",
    },
    "& .MuiInputBase-input": {
      '::placeholder': {
        color: props.error ? '#E9082A' : '#A5A5A5',
        fontSize: '0.9rem',
        opacity: 1
      },
    },
  }

  return (
    <div className="flex flex-col">
      <label className="text-sm text-swopa-primary-dark-blue mb-2" htmlFor={props.id}>
        {props.label}
      </label>
      <div className="grid grid-cols-2 gap-2">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            openTo={'year'}
            views={['year', 'month', 'day']}
            defaultValue={date}
            onChange={setDate}
            format="dd/MM/yyyy"
            slotProps={{
              textField: { placeholder: 'dd/mm/yyyy' }
            }}
            sx={datePickerStyles}
          />
          </LocalizationProvider>
      </div>
      {props.error && props.errorMessage && (
        <span className="text-swopa-warning-red mt-2 text-xs">{props.errorMessage}</span>
      )}
    </div>
  );
}
