import Sidebar from './Navigation/Sidebar';
import Footer from 'components/Footer';
import Header from 'components/Navigation/Header';
import { RequireAuth } from 'components/RequireAuth';
import StickyBookingButton from './StickyBookingButton';

interface MainLayoutProps {
  title: string;
  children: React.ReactNode;
}

export default function MainLayout({ title, children }: MainLayoutProps) {
  return (
    <RequireAuth>
      <div className="w-full min-h-screen grid xl:grid-cols-layout-desktop bg-swopa-hover-grey">
        <div className="hidden xl:block">
          <Sidebar />
        </div>
        <main className="bg-swopa-hover-grey grid grid-rows-dashboard min-h-screen">
          <Header title={title} />
          <section>
            <h1 className="ml-4 sm:mx-0 sm:text-center xl:text-left md:mx-[35px] xl:mx-20 mt-8 xl:mt-0">
              {title}
            </h1>
            {children}
          </section>
          <StickyBookingButton />
          <Footer />
        </main>
      </div>
    </RequireAuth>
  );
}
