import classNames from 'classnames';
import { Field } from 'formik';
import { ReactElement } from 'react';

interface InputProps {
  id: string;
  name: string;
  label: string | ReactElement;
  className?: string;
}

export default function FormikCheckbox(props: InputProps) {
  return (
    <div className={classNames('flex items-center p-4', props.className)}>
      <Field
        type="checkbox"
        name={props.name}
        id={props.id}
        className="focus:ring-swopa-primary-dark-blue h-4 w-4 text-swopa-primary-dark-blue rounded"
      />
      <label
        htmlFor={props.id}
        className="ml-3 block text-base font-semibold text-swopa-primary-dark-blue cursor-pointer"
      >
        {props.label}
      </label>
    </div>
  );
}
