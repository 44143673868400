import useLocalizedMarketingPageLinks from '../hooks/useLocalizedMarketingPageLinks';
import * as CookieConsent from 'vanilla-cookieconsent';

export default function Footer() {
  const links = useLocalizedMarketingPageLinks();
  const displayConsentManager =
    (process.env.REACT_APP_GOOGLE_TAG_SERVER_SIDE_URL &&
      process.env.REACT_APP_GOOGLE_TAG_NO_SCRIPT_URL) ||
    process.env.REACT_APP_GOOGLE_TAG;

  return (
    <footer className="mx-4 md:mx-0 mt-16 mb-14 xl:mb-0">
      <hr className="bg-swopa-border" />
      <div className="flex items-center justify-center">
        <div className="flex flex-col md:flex-row justify-center md:justify-end text-footer max-w-[1600px] w-full px-4 md:px-8 xl:px-20 py-4 items-center text-swopa-secondary-grey">
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-[26px]">
            {displayConsentManager && (
              <button
                type="button"
                className="hover:text-swopa-accent-green"
                onClick={() => {
                  CookieConsent.showPreferences();
                }}
              >
                Cookie-Einstellungen
              </button>
            )}
            <a
              href={links.impressum}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-swopa-accent-green"
            >
              Impressum
            </a>
            <a
              href={links.privacy_policy}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-swopa-accent-green"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
