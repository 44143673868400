import { PatientNameShort } from 'hooks/usePatient';
import classnames from 'classnames';

interface UserIconProps {
  size?: 'default' | 'small';
  children?: React.ReactNode;
  className?: string;
}

export default function UserIcon({ size = 'default', children, className }: UserIconProps) {
  return (
    <div
      className={classnames(
        'rounded-full border-2 border-swopa-accent-green mr-0 md:mr-4 bg-swopa-primary-dark-blue flex justify-center items-center',
        {
          'w-12 h-12': size === 'default',
          'w-8 h-8': size === 'small',
        },
        className,
      )}
    >
      <div
        className={classnames('rounded-full p-1 text-white font-bold', {
          'text-[20px]': size === 'default',
          'text-xs': size === 'small',
        })}
      >
        {children ? <>{children}</> : <PatientNameShort />}
      </div>
    </div>
  );
}
