import { useCallback, useMemo } from 'react';

import Step from './Step';
import CallToAction from './CallToAction';
import {
  PREASSESSMENT_DONT_EXIST,
  PREASSESSMENT_UNDER_EVALUTATION,
  READY_TO_BOOK_FIRST_APPOINTMENT,
  SHOW_FOLLOW_UP_BOOKING,
} from 'hooks/usePatientStatus';
import usePatient from 'hooks/usePatient';
import useLocalizedPrices from '../../../hooks/useLocalizedPrices';
import type { CountryCode } from '../../../localizations/types';
import useQuestionnaire from 'components/Fragebogen/useQuestionnaire';

export default function InfoBanner({
  firstAppointmentUrl,
}: {
  firstAppointmentUrl: {
    inPerson: string;
    virtual: string;
  };
}) {
  const LOCALIZATION = process.env.REACT_APP_COUNTRY_CODE as CountryCode;
  const { data: patient } = usePatient();
  const { first_appointment_online, first_appointment_clinic, follow_up_online } =
    useLocalizedPrices();
  const questionnaire = useQuestionnaire();

  const patientStatusData = useMemo(
    () => ({
      [PREASSESSMENT_DONT_EXIST]: {
        step: 1,
        status: PREASSESSMENT_DONT_EXIST,
        title: 'Fragebogen ausfüllen',
        description:
          'Mithilfe des Fragebogens überprüfen wir, ob Sie grundsätzlich für eine Cannabistherapie geeignet sind.',
        tooltip:
          'Der Fragebogen hilft unserem Ärzteteam, Ihre Situation besser zu verstehen. Damit können wir einschätzen, ob eine Therapie mit medizinischem Cannabis für Sie in Frage kommt. Weiterhin dient der Fragebogen als Vorbereitung für Ihren behandelnden Arzt bzw. Ihre behandelnde Ärztin.',
        ctaTitle: 'Fragebogen starten',
        ctaDescription: 'Nur 7 Minuten',
        theme: 'dark',
      },
      [PREASSESSMENT_UNDER_EVALUTATION]: {
        step: 1,
        status: PREASSESSMENT_UNDER_EVALUTATION,
        title: 'Fragebogen wird geprüft',
        description:
          'Sie können dem Arzt bereits vor Ihrem Ersttermin alle relevanten Dokumente per Upload bereitstellen.',
        tooltip:
          'Ihr Fragebogen zum medizinischen Screening befindet sich zurzeit in ärztlicher Prüfung. Sobald die Prüfung abgeschlossen ist, werden wir Sie per E-Mail informieren.',
        ctaTitle: 'Dokumente hochladen',
        ctaDescription: 'Befunde, Arztbriefe, etc.',
        theme: 'light',
      },
      [READY_TO_BOOK_FIRST_APPOINTMENT]: {
        step: 2,
        status: READY_TO_BOOK_FIRST_APPOINTMENT,
        title: 'Ersttermin buchen',
        description: `${LOCALIZATION === 'DE'
          ? `Der Termin zum ärztlichen Beratungsgespräch wird nach GOÄ berechnet. Ein Termin vor Ort kostet <b>109&nbsp;€</b> und ein online Termin <b>75&nbsp;€.</b>`
          : 'Persönliche Beratung vor Ort oder per Video-Call bequem von zu Hause aus. Erstgespräch <b>129&nbsp;CHF</b> und Folgegespräch <b>99&nbsp;CHF.</b>'}`,
        ctaTitle: 'Jetzt online buchen',
        ctaDescription: 'Die Zahlung erfolgt per Rechnung.',
        theme: 'dark',
      },
    }),
    [],
  );

  const { preassessmentDontExist, readyToBookFirstAppointment } = patientStatusData;

  const ctaData = !questionnaire ? preassessmentDontExist : readyToBookFirstAppointment;

  const StepOne = useCallback(() => {
    let data;
    if (!questionnaire) {
      data = { ...preassessmentDontExist, activeStatus: 'active' };
    } else {
      data = {
        title: 'Ihr medizinischer Fragebogen wurde erfolgreich übermittelt!',
        activeStatus: 'completed',
        //Somebody didn't know what typing is
        step: 1,
        status: '',
        description: '',
        ctaTitle: '',
        ctaDescription: '',
        theme: '',
      };
    }
    return <Step data={data} />;
  }, [questionnaire]);

  const StepTwo = useCallback(() => {
    let data = readyToBookFirstAppointment as any;
    if (questionnaire) {
      data = {
        ...readyToBookFirstAppointment,
        activeStatus: 'active',
        firstAppointmentUrl,
      };
    }
    return <Step data={data} />;
  }, [questionnaire]);

  if (!patient) return null;

  if (patient.eligible_for_treatment === 'no') {
    return (
      <div className="flex flex-col items-center justify-center">
        <p className="text-swopa-primary-dark-blue text-center max-w-lg text-lg mb-5">
          Sie können leider nicht fortfahren, da Sie die folgenden Anforderungen nicht erfüllen:
        </p>
        <BulletPoints points={patient.eligible_for_treatment_reasons} />
      </div>
    );
  }

  return (
    <>
      {questionnaire && (
        <p className="font-radikal text-swopa-primary-dark-blue sm:px-5 xl:px-0 mb-6">
          Vielen Dank, dass Sie den medizinischen Fragebogen ausgefüllt haben. Vereinbaren Sie jetzt einen Termin mit einem unserer Ärzte, um zu besprechen, ob eine medizinische Cannabisbehandlung für Sie infrage kommt.
        </p>
      )}
      <div className="flex flex-col md:flex-row bg-white border border-swopa-border rounded p-4">
        <ul className="w-full md:w-1/2 mr-8">
          <StepOne />
          <StepTwo />
        </ul>
        {/* show on desktop */}
        <CallToAction
          data={{
            ...ctaData,
            firstAppointmentUrl,
          }}
          status={ctaData.status}
          className="hidden md:flex"
        />
      </div>
    </>
  );
}

type BulletPointProps = {
  points: string[];
};

function BulletPoints({ points }: BulletPointProps) {
  return (
    <ul className="list-disc text-swopa-primary-dark-blue mb-5 space-y-1 ml-4">
      {points.map((point) => (
        <li key={point}>{point}</li>
      ))}
    </ul>
  );
}
