import classNames from 'classnames';
import { useField } from 'formik';

type FormikBinaryProps = {
  name: string;
};

export default function FormikBinary({ name }: FormikBinaryProps) {
  const [_field, meta, helpers] = useField(name);

  const { value, error } = meta;
  const { setValue } = helpers;

  const options = [
    { label: 'Ja', value: true },
    { label: 'Nein', value: false },
  ];

  return (
    <div className="grid grid-cols-1 gap-4">
      {options.map((option) => (
        <div
          className={classNames(
            'flex items-center p-4 rounded-lg border hover:bg-swopa-secondary-light-blue-hover/30',
            {
              'bg-white': value !== option.value,
              'bg-swopa-secondary-light-blue-hover/30': value === option.value,
              'border-swopa-secondary-grey': !error,
              'border-swopa-warning-red': error,
            },
          )}
        >
          <input
            id={name + '_' + option.value}
            name={name}
            type="radio"
            className="focus:ring-swopa-primary-dark-blue h-4 w-4 text-swopa-primary-dark-blue rounded"
            checked={value === option.value}
            onChange={() => setValue(option.value)}
          />
          <label
            htmlFor={name + '_' + option.value}
            className="ml-3 block text-base font-semibold text-swopa-primary-dark-blue cursor-pointer"
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
}
