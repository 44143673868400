import InfoBanner from 'components/Therapy/InfoBanner/InfoBanner';
import usePatient from 'hooks/usePatient';
import MyPrescriptions from 'components/Therapy/MyPrescriptions';
import Title from 'components/util/Title';
import { NavLink } from 'react-router-dom';
import usePrescriptions from 'components/Therapy/usePrescriptions';

/**
 * Page displaying all historical appointments
 * @returns ReactElement
 */
export default function History() {
  const { data } = usePatient();
  const { data: prescriptions } = usePrescriptions();

  if (!data) {
    return <></>;
  }

  const hasPrescriptions = !!prescriptions?.length;

  return (
    <>
      <Title title="Therapie" />
      {!hasPrescriptions && (
        <InfoBanner
          firstAppointmentUrl={{
            inPerson: data.first_appointment_in_person_url,
            virtual: data.first_appointment_virtual_url,
          }}
        />
      )}
      <div className="border-b-swopa-border mt-10 flex flex-col border-b">
        {hasPrescriptions && (
          <div className="flex">
            <NavLink
              to={'/therapie/verschreibungen'}
              className={(navData) =>
                `not-first flex flex-col px-4 pb-1 ${
                  navData.isActive
                    ? 'border-swopa-primary-dark-blue text-swopa-primary-dark-blue border-b-2'
                    : 'text-swopa-passive'
                }`
              }
            >
              Aktuell
            </NavLink>
            <NavLink
              to={'/therapie/verlauf'}
              className={(navData) =>
                `not-first flex flex-col px-4 pb-1 ${
                  navData.isActive
                    ? 'border-swopa-primary-dark-blue text-swopa-primary-dark-blue border-b-2'
                    : 'text-swopa-passive'
                }`
              }
            >
              Verlauf
            </NavLink>
          </div>
        )}
      </div>
      <div className="w-full">
        <MyPrescriptions title="" history={true} />
      </div>
    </>
  );
}
