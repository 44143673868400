import { NavLink } from 'react-router-dom';
import MyPrescriptions from 'components/Therapy/MyPrescriptions';
import Title from 'components/util/Title';
import usePatient from 'hooks/usePatient';
import usePrescriptions from 'components/Therapy/usePrescriptions';
import InfoBanner from 'components/Therapy/InfoBanner/InfoBanner';

/**
 * Page displaying all active appointments
 * @returns ReactElement
 */
export default function Prescriptions() {
  const { data } = usePatient();
  const { data: prescriptions } = usePrescriptions();

  //TODO: Handle loading
  if (!data || !prescriptions) {
    return <></>;
  }

  const hasPrescriptions = !!prescriptions?.length;

  const historicalNavigation = (
    <>
      <div className="flex flex-col border-b border-b-swopa-border mt-10">
        <div className="flex">
          <NavLink
            to={'/therapie/verschreibungen'}
            className={(navData) =>
              `flex flex-col not-first px-4 pb-1 ${
                navData.isActive
                  ? 'border-b-2 border-swopa-primary-dark-blue text-swopa-primary-dark-blue'
                  : 'text-swopa-passive'
              }`
            }
          >
            Aktuell
          </NavLink>
          <NavLink
            to={'/therapie/verlauf'}
            className={(navData) =>
              `flex flex-col not-first px-4 pb-1 ${
                navData.isActive
                  ? 'border-b-2 border-swopa-primary-dark-blue text-swopa-primary-dark-blue'
                  : 'text-swopa-passive'
              }`
            }
          >
            Verlauf
          </NavLink>
        </div>
      </div>
      <div className="w-full mt-8">
        <MyPrescriptions title="" />
      </div>
    </>
  );

  const firstAppointmentBooking = (
    <InfoBanner
      firstAppointmentUrl={{
        inPerson: data.first_appointment_in_person_url,
        virtual: data.first_appointment_virtual_url,
      }}
    />
  );

  return (
    <>
      <Title title="Therapie" />
      {hasPrescriptions ? historicalNavigation : firstAppointmentBooking}
    </>
  );
}
