import { useMemo } from 'react';
import AppointmentWrapper from './AppointmentWrapper';
import Appointment, { AppointmentType } from './PrescriptionSteps/Appointment';
import Precheck, { PrecheckProps } from './PrescriptionSteps/Precheck';
import FinalPrescription, { FinalPrescriptionProps } from './PrescriptionSteps/FinalPrescription';

interface FollowupPrescriptionProps {
  appointment: AppointmentType;
  prescriptions?: FinalPrescriptionProps[];
  startOpen?: boolean;
  appointmentType?: 'first' | 'followup';
}

interface FirstPrescriptionProps extends FollowupPrescriptionProps {
  precheck?: PrecheckProps;
}
/**
 * Component to build up entire appointment + prescription information
 * @param precheck used to determine whether it is the first or an followup appointment
 * @param appointment props used to display appointment data
 * @param prescriptions props used to display final prescription data
 * @returns
 */
export function PrescriptionType({
  precheck,
  appointment,
  prescriptions,
  startOpen = false,
  appointmentType = 'followup',
}: FirstPrescriptionProps) {
  const steps = useMemo(() => {
    if (!precheck) {
      return [
        <Appointment {...appointment} />,
        prescriptions?.map((item, index) => (
          <FinalPrescription total={prescriptions?.length || 0} index={index} {...item} />
        )),
      ];
    }
    if (!precheck.ready) {
      return [<Precheck {...precheck} />];
    }
    return [
      <Precheck {...precheck} />,
      <Appointment {...appointment} />,
      prescriptions?.map((item, index) => (
        <FinalPrescription total={prescriptions?.length || 0} index={index} {...item} />
      )),
    ];
  }, [appointment, precheck, prescriptions]);

  const state = useMemo(() => {
    if (!appointment.ready || !appointment?.appointmentProps?.date)
      return { location: 'Ausstehend' };
    if (appointment && appointment.appointmentProps.state === 'not_attended') {
      return { location: 'Verpasst', error: true, date: appointment.appointmentProps.date };
    }
    return {
      date: appointment.appointmentProps.date,
      location:
        (appointment.appointmentProps.format === 'video_consultation' ||
          appointment.appointmentProps.format === 'first_video') ? 'Videoanruf' : 'Vor Ort',
    };
  }, [appointment]);

  return (
    <AppointmentWrapper
      title={appointmentType === 'first' ? 'Ersttermin' : 'Folgetermin'}
      steps={steps}
      startOpen={startOpen}
      state={state}
    />
  );
}
export default PrescriptionType;
